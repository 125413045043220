<template>
    <div class="labelcreat">
        <div class="alert alert-success" style="margin-top: 10px !important;margin-left: 10px !important;margin-right: 10px !important;">
                用户在微信卡包中打开次卡，商家使用扫码设备或者手动输入卡号，点击查询验证次卡信息    
        </div>
        <el-card class="card-contanier" style="height:200px;margin-top: 100px !important;overflow-y:auto;overflow-x:hidden;">
           
            <div class="input-group m-t-10" style="margin-top: 70px !important; width: 521px; margin: 0 auto; ">
                <el-input v-model="cardRoll" placeholder="请输入计次卡"></el-input>
                <span class="input-group-btn">
                    <el-button type="primary" icon="el-icon-search" @click="cardQuery">查询</el-button>
                </span>
            </div>
        </el-card>
        <template v-if="disabled">
            <el-table
                ref="multipleTables"
                :data="addData"
                tooltip-effect="dark"
                border
                style="width: 100%"
                stripe
                size="small"
                :row-style="{ height: '10px' }"
                :cell-style="{ padding: '1px 0' }"
                class="tjhy-dialog-table"
                
            >
                <el-table-column
                prop="sri_card_num"
                label="卡卷号"
                show-overflow-tooltip
                align="center"
                >
                </el-table-column>
                <el-table-column
                prop="card_name"
                label="卡卷名称"
                show-overflow-tooltip
                align="center"
                >
                </el-table-column>
                <el-table-column
                prop="pvip_cards"
                label="卡卷类型"
                show-overflow-tooltip
                align="center"
                >
                <template>
                    {{"计次卡"}}
                </template>
                </el-table-column>
                <el-table-column
                prop="pvip_name"
                label="会员名称"
                show-overflow-tooltip
                align="center"
                >
                </el-table-column>
                <el-table-column
                prop="pvip_phone"
                label="会员手机号"
                show-overflow-tooltip
                align="center"
                >
                </el-table-column>
                <el-table-column prop="sri_surp_count" label="剩余次数" show-overflow-tooltip align="center"></el-table-column>
                <el-table-column prop="overdm" label="有效期" show-overflow-tooltip align="center"></el-table-column>
                <!-- <el-table-column prop="vcard_me_name" label="使用状态" show-overflow-tooltip align="center"></el-table-column> -->
                <el-table-column
                prop="icon"
                label="操作"
                width="100"
                align="center"
                fixed="right"
            >
                <template slot-scope="scope">
                <el-button @click="writeOff(scope.row)" type="text" size="small"
                    >核销</el-button
                >
                </template>
            </el-table-column>
            </el-table>
        </template>
    </div>
</template>
  
  <script>
import api from "../../api/writeOff";
import user from "@/api/user";
  export default {
    name: "writeOff",
    data() {
        return {
            cardRoll:'',
            addData:[],
            disabled:false,
            soeId:''
        }
    },
    created() {
        this.getUserInfo();
    },
    methods: {
        async getUserInfo(){
            await user.userinfo().then((res) => {
                this.soeId = res.data.data.result.soeId;
            });
        },
        async cardQuery(){
            if(this.cardRoll === ''){
                this.$notify({
                    title: "提示信息",
                    message: "请输入卡卷号",
                    type: "error",
                    position: "bottom-right",
                });
                return;
            }
            await api.getTimeCardByCardNum(this.cardRoll).then(res =>{
                if(res.data.data.result){
                    this.writeOffAdd();
                }else{
                    this.$notify({
                        title: "提示信息",
                        message: "未查询到卡卷",
                        type: "error",
                        position: "bottom-right",
                    });
                    this.disabled = false;
                }
            })
        },
        writeOffAdd(){
            api.getListByTimeCardNum(this.cardRoll).then(res =>{
                if(res.data.data.result.length !== 0){
                    this.disabled = true;
                    this.addData = res.data.data.result;
                }else{
                    this.$notify({
                        title: "提示信息",
                        message: "未查询到卡卷",
                        type: "error",
                        position: "bottom-right",
                    });
                    this.disabled = false;
                }
               
            })
        },
        writeOff(row){
            api.getWriteOff(row.sri_card_num,0).then(res =>{
                if(res.data.data.result === 'success'){
                    this.$notify({
                        title: "提示信息",
                        message: "卡卷核销成功",
                        type: "success",
                        position: "bottom-right",
                    });
                    this.writeOffAdd();
                }else if(res.data.data.result === 'timeCard'){
                    this.$notify({
                        title: "提示信息",
                        message: "该卡卷已核销",
                        type: "error",
                        position: "bottom-right",
                    });
                }
            })
        }
    }
  };

  </script>
  
  <style scoped>
  .card-contanier{
    margin-bottom: 30px;
  }
  .alert{
    border-radius: 6px;
  }
  .alert-success{
    background-color: #f4f8fb !important;
    color: #7a8094;
    border: aliceblue;
    margin: 15px 0px 0px 0px !important;
    padding: 10px;
  }
  .m-t-10{
    margin-top:50px !important
  }
  .input-group{
    position:relative;
    display:table;
    border-collapse:separate;
  }
  .input-group-btn{
    width:1%;
    white-space:nowrap;
    vertical-align:middle;
    display:table-cell;
  }
  </style>
  